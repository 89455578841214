<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="고객센터" tabActive="문의 게시판" bg="/images/center/visual.jpg">
                <template #title>고객센터</template>
            </sub-head-primary>
        </template>
        <page-section-primary class="page-section--first page-section--last">

            <!-- S: 임시 퍼블리싱 폼 -->
            <div class="page-section__content">
                <v-divider class="grey darken-4" style="border-width: 2px 0 0" />

                <v-row no-gutters align="center">
                    <v-col cols="12" md="auto" class="d-flex flex-column">
                        <div class="pt-16px pb-4px px-10px px-md-20px py-md-16px w-140px">제목</div>
                    </v-col>
                    <v-col cols="12" md="">
                        <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">
                            <v-text-field  v-model="form.subject" hide-details outlined placeholder="제목을 입력하세요." />
                        </div>
                    </v-col>
                </v-row>
                <v-divider />

                <v-row no-gutters align="center">
                    <v-col cols="12" md="auto" class="d-flex flex-column">
                        <div class="pt-16px pb-4px px-10px px-md-20px py-md-16px w-140px">공개여부</div>
                    </v-col>
                    <v-col cols="12" md="">
                        <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">
                            <v-radio-group v-model="form.status" row hide-details>
                                <v-radio color="tertiary" label="공개" value="public"></v-radio>
                                <v-radio color="tertiary" label="비공개" value= "private"></v-radio>
                            </v-radio-group>
                        </div>
                    </v-col>
                </v-row>
                <v-divider />

                <v-row no-gutters align="center">
                    <v-col cols="12" md="auto" class="d-flex flex-column">
                        <div class="pt-16px pb-4px px-10px px-md-20px py-md-16px w-140px">작성자</div>
                    </v-col>
                    <v-col cols="12" md="">
                        <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">
                            <v-text-field v-model="form.name" dense hide-details outlined placeholder="작성자 이름 또는 닉네임을 입력하세요." />
                        </div>
                    </v-col>
                </v-row>
                <v-divider />

                <v-row no-gutters align="center">
                    <v-col cols="12" md="auto" class="d-flex flex-column">
                        <div class="pt-16px pb-4px px-10px px-md-20px py-md-16px w-140px">비밀번호</div>
                    </v-col>
                    <v-col cols="12" md="">
                        <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">
                            <v-text-field v-model="form.password" type="password" hide-details outlined placeholder="게시글 비밀번호를 입력하세요." />
                        </div>
                    </v-col>
                </v-row>
                <v-divider />

                <v-row no-gutters align="center">
                    <v-col cols="12" md="auto" class="d-flex flex-column">
                        <div class="pt-16px pb-4px px-10px px-md-20px py-md-16px w-140px">내용</div>
                    </v-col>
                    <v-col cols="12" md="">
                        <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">
                            <v-textarea v-model="form.content" outlined hide-details no-resize placeholder="내용을 입력하세요." />
                        </div>
                    </v-col>
                </v-row>
                <v-divider />
            </div>
            <!-- E: 임시 퍼블리싱 폼 -->

            <!-- <form-input code="inquiry" skin="inquiry" /> -->

            <div class="page-section__content">
                <v-row class="row--xs" justify="center">
                    <v-col cols="auto">
                        <v-btn x-large tile outlined class="w-160px" color="grey lighten-3" @click="$router.go(-1)">
                            <span class="grey--text">취소</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn x-large tile class="w-160px white--text" color="black" @click="save">작성하기</v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";

import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import InquiryList from "@/components/client/center/inquiries/inquiry-list.vue";
import InquiryView from "@/components/client/center/inquiries/inquiry-view.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        InquiryList,
        InquiryView,
    },
    mounted(){
        this.init();
    },
    data(){
        return{
            form: {
                subject: "",
                status: "public",
                name: "",
                password: "",
                content: ""
            },
            isValid: false
        }
    },
    methods: {
        async init(){
            if(this._inquiry) this.search();
        },
        async search(){
            try {
                var { question } = await api.v1.center.questions.get({ _id: this.$route.params._inquiry });

                this.form = question;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async save(){
            try {
                let { put, post } = api.v1.center.questions;
                
                if(!this.validation()) return;
                this.form = {...this.form, password: CryptoAES.encrypt(this.form.password)};
                this.form._id ? await put(this.form) : await post(this.form);

                alert("고객님의 문의가 정상적으로 접수되었습니다.");

                this.$router.push("/center/inquiry");
            }catch(error){
                console.error(error);
                alert(error.repsonse ? error.response.data.message : error.message);
            }
        },
        validation(){
            if(!this.form.subject) throw new Error("제목을 입력해주세요.");
            if(!this.form.status) throw new Error("공개여부를 선택해주세요.");
            if(!this.form.name) throw new Error("이름을 입력해주세요.");
            if(!this.form.password) throw new Error("비밀번호를 입력해주세요.");
            if(!/^[0-9]{4,}$/.test(this.form.password)) throw new Error("비밀번호는 4글자 이상이고 숫자만 허용됩니다.");
            if(!this.form.content) throw new Error("내용을 입력해주세요.");

            return true;
        }
    },
    computed: {
        _inquiry(){
            return this.$route.params._inquiry;
        }
    }
};
</script>

<style lang="scss" scoped>
.v-textarea::v-deep {
    .v-input__slot {
        min-height: 400px;
    }
}
</style>
