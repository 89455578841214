var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "고객센터",
            "tabActive": "문의 게시판",
            "bg": "/images/center/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("고객센터")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "page-section__content"
  }, [_c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-column",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "pt-16px pb-4px px-10px px-md-20px py-md-16px w-140px"
  }, [_vm._v("제목")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_c('v-text-field', {
    attrs: {
      "hide-details": "",
      "outlined": "",
      "placeholder": "제목을 입력하세요."
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  })], 1)])], 1), _c('v-divider'), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-column",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "pt-16px pb-4px px-10px px-md-20px py-md-16px w-140px"
  }, [_vm._v("공개여부")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.status,
      callback: function ($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, [_c('v-radio', {
    attrs: {
      "color": "tertiary",
      "label": "공개",
      "value": "public"
    }
  }), _c('v-radio', {
    attrs: {
      "color": "tertiary",
      "label": "비공개",
      "value": "private"
    }
  })], 1)], 1)])], 1), _c('v-divider'), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-column",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "pt-16px pb-4px px-10px px-md-20px py-md-16px w-140px"
  }, [_vm._v("작성자")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "작성자 이름 또는 닉네임을 입력하세요."
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)])], 1), _c('v-divider'), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-column",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "pt-16px pb-4px px-10px px-md-20px py-md-16px w-140px"
  }, [_vm._v("비밀번호")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "hide-details": "",
      "outlined": "",
      "placeholder": "게시글 비밀번호를 입력하세요."
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1)])], 1), _c('v-divider'), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-column",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "pt-16px pb-4px px-10px px-md-20px py-md-16px w-140px"
  }, [_vm._v("내용")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "no-resize": "",
      "placeholder": "내용을 입력하세요."
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)])], 1), _c('v-divider')], 1), _c('div', {
    staticClass: "page-section__content"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-160px",
    attrs: {
      "x-large": "",
      "tile": "",
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("취소")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-160px white--text",
    attrs: {
      "x-large": "",
      "tile": "",
      "color": "black"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("작성하기")])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }